<template>
  <div class="d-flex flex-column mt-6">
    <v-row no-gutters>
      <v-col cols="12">
        <label>{{ $t("comments.addComment") }}</label>
        <v-textarea
          v-model="comment"
          outlined
          no-resize
          :disabled="disabled"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="mr-2"
          outlined
          @click="comment = null"
          :disabled="disabled"
        >
          {{ $t("comments.clear") }}
        </v-btn>
        <v-btn
          :disabled="!comment ? true : false || disabled"
          color="primary"
          @click="saveComment"
        >
          {{ $t("comments.save") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters :class="container ? 'comments-container' : ''" class="mt-6">
      <v-col cols="12" v-for="comment in comments" :key="comment.id">
        <v-row no-gutters>
          <v-col cols="1">
            <StatusAvatar :userId="comment.user.id"></StatusAvatar>
          </v-col>
          <v-col cols="11" class="d-flex align-center pa-0 text-caption">
            {{ comment.date | dateFormat("DD.MM.YYYY HH:mm") }} <v-btn v-if="remove" plain color="error" @click="$emit('comment-remove', comment.id)">{{ $t("common.remove") }}</v-btn>
          </v-col>
          <v-col cols="11" offset="1" class="text-subtitle-2">
            {{ comment.text }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusAvatar from '../StatusAvatar.vue'

export default {
  name: 'Comments',
  components: {
    StatusAvatar
  },
  props: {
    comments: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    container: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    remove: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      comment: null
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/users'
    })
  },
  methods: {
    saveComment () {
      this.$emit('comment-saved', this.comment)
      this.comment = null
    }
  }
}
</script>

<style lang="scss" scoped>
.comments-container {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
