<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <Comments
            :comments="comments"
            :remove="isAdmin"
            :container="false"
            @comment-saved="sendComment"
            @comment-remove="removeComment"
          />
        </v-col>
        <v-col cols="6">
          <div>
          <v-row>
            <v-col cols="6">
          <label for="bokAssign">{{ $t('saveIssue.salesAssign') }}</label>
          <v-select
            v-model="details.salesHandledBy"
            id="salesAssign"
            :items="salesUsersToAssign"
            item-text="name"
            item-value="id"
            return-object
            outlined
            required
            validate-on-blur
            dense
            single-line
            :disabled="!$permission('AssigningSalesperson')"
          ></v-select>
        </v-col>
          </v-row>
          <v-row>
        <v-col
          cols="6"
          class="d-flex justify-space-between mb-2">
          <v-btn
            color="primary"
            :disabled="!$permission('AssigningSalesperson') || !details.salesHandledBy"
            @click="showSignSalesUserDialog(details.salesHandledBy)"
          >
            {{ $t("saveIssue.sign") }}
          </v-btn>
          <v-btn
            v-if="details.salesHandledBy"
            color="primary"
            @click="showResignSalesUserDialog"
          >
            {{ $t("saveIssue.resign") }}
          </v-btn>
        </v-col>
          </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
      <ConfirmDialog
      v-model="signSalesUserDialog"
      :title="$t('saveIssue.signDialogTitle')"
      @confirm="updateSalesUserChange"
      @close="closeSignUserDialog"/>
    <ConfirmDialog
      v-model="resignSalesUserDialog"
      :title="resignUserDialogTitle"
      @confirm="resignSalesUser()"
      @close="closeResignSalesUserDialog()"/>
  </v-card>
</template>

<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import Comments from '@/components/comments/Comments.vue'
import ConfirmDialog from '@/components/ConfirmDialog'
export default {
  name: 'CommentsTab',
  components: {
    Comments,
    ConfirmDialog
  },
  data () {
    return {
      comments: [],
      details: {},
      loading: false,
      salesUsersToAssign: null,
      resignSalesUserDialog: false,
      signSalesUserDialog: false,
      selectedUser: null
    }
  },
  async created () {
    await this.getComments()
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    }),
    resignUserDialogTitle () {
      return this.$t('saveIssue.resignDialogTitle')
    }
  },
  methods: {
    async getComments () {
      this.loading = true
      try {
        const { data } = await Service.get('/CustomerService/GetComments', { companyId: this.$route.params.id })
        this.comments = data

        const det = await Service.get(
          '/Company/GetCompanyDetails',
          { companyId: this.$route.params.id }
        )
        this.details = det.data
        const { data: salesUsersToAssign } = await Service.get('User/GetSalesUsers')
        this.salesUsersToAssign = salesUsersToAssign
      } catch (error) {}

      this.loading = false
    },
    async sendComment (comment) {
      this.loading = true
      try {
        await Service.post('/Company/AddComment', {
          companyId: this.$route.params.id,
          text: comment
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        await this.getComments()
        this.comment = null
      } catch (error) {}
      this.loading = false
    },
    async removeComment (id) {
      this.loading = true
      try {
        await Service.delete('/Company/DeleteComment', {
          commentId: id
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        await this.getComments()
      } catch (error) {}
      this.loading = false
    },
    async updateSalesUserChange () {
      try {
        await Service.post('CustomerService/AssignSalesman', {
          companyId: this.$route.params.id,
          userId: this.selectedUser.id
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.signSalesUserDialog = false
      } catch (e) {
      } finally {
        this.selectedUser = null
      }
    },
    async resignSalesUser () {
      try {
        await Service.post('CustomerService/AssignSalesman', {
          companyId: this.$route.params.id,
          userId: null
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.resignSalesUserDialog = false
        this.details.salesHandledBy = null
      } catch (e) {
      } finally {
        this.selectedUser = null
      }
    },
    closeSignUserDialog () {
      this.selectedUser = null
      this.signSalesUserDialog = false
    },
    showSignSalesUserDialog (user) {
      this.selectedUser = user
      this.signSalesUserDialog = true
    },
    closeSignSalesUserDialog () {
      this.selectedUser = null
      this.signSalesUserDialog = false
    },
    showResignSalesUserDialog () {
      this.resignSalesUserDialog = true
    },
    closeResignSalesUserDialog () {
      this.resignSalesUserDialog = false
    }
  }
}
</script>
<style>
</style>
