<template>
<div>
  <v-card flat>
    <v-card-text>
    <data-table
      ref="Employees"
      :headers="headers"
      endpoint="/Company/GetCompanyUsersWithPagination"
      :additionalParams="{ companyId }"
      :search="searchInputValue"
      serverPagination
    >
        <template v-slot:item.name="{ item }">
          {{ item.name }} {{ item.surname }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip class="ma-2" small label color="white">
            {{ getUserStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.chat="{ item }">
          <v-btn icon @click="startConversationWithUser(item.id)">
            <v-icon class="w-50" color="info">mdi-chat-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.role="{ item }">
          {{ getUserRole(item.role) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
           <v-list>
            <v-list-item link @click="block(item)" v-if="item.status != 2">
                <v-list-item-title>{{
                  $t("users.blockUser")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="unblock(item)" v-if="item.status == 2">
                <v-list-item-title>{{
                  $t("users.unblockUser")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.role !== 98" link @click="openSetRoleEmployeeDialog(item)">
                <v-list-item-title>
                  {{ $t('users.dialogs.setRole.title')}}
                </v-list-item-title>
              </v-list-item>
<!--            TODO: Implement in future-->
<!--              <v-list-item link @click="goToProfile(item)">-->
<!--                <v-list-item-title>{{-->
<!--                    $t('users.goToProfile')-->
<!--                  }}</v-list-item-title>-->
<!--              </v-list-item>-->
           </v-list>

          </v-menu>
        </template>
      </data-table>
    </v-card-text>
  </v-card>

   <v-dialog v-model="setRoleDialog" persistent max-width="500px">
      <v-card class="py-4">
        <v-card-title>{{ $t('users.dialogs.setRole.title')}}</v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="setRoleForm" :disabled="loading">
              <v-row>
                <v-col class="pa-0" cols="12">
                   <v-select
                      v-model="setRoleValue"
                      :items="roles"
                      item-value="id"
                      item-text="description"
                      class="text-left"
                      outlined
                      dense
                      persistent-hint
                    ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer></v-spacer>
          <v-btn
            color="secodary"
            outlined
            depressed
            class="mr-2"
            :disabled="loading"
            @click="closeSetRoleDialog"
          >
            {{ $t('common.cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            @click="setRoleEmployee"
          >
            {{ $t('common.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  <block-user
      :dialog="blockDialog"
      :user="selectedUser"
      :loading="loadingDialog"
      @close="blockDialogClose"
      @submit="blockDialogSubmit"
    ></block-user>
  <unblock-user
    :dialog="unblockDialog"
    :user="selectedUser"
    :loading="loadingDialog"
    @cancel="unblockDialogClose"
    @unblock="unblockDialogSubmit"
  ></unblock-user>
  </div>
</template>

<script>
import Service from '@/services'
import { getEnumDescription } from '@/utils/helpers'
import BlockUser from './Dialogs/BlockUser.vue'
import UnblockUser from './Dialogs/UnblockUser.vue'
import * as roleTypes from '@/types/role-types'
import { startConversation } from '@/signalr/chat'

export default {
  name: 'Employees',
  components: {
    BlockUser,
    UnblockUser
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('companiesDetails.userName'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: this.$t('companiesDetails.id'), sortable: false, value: 'displayId' },
        { text: this.$t('companiesDetails.email'), sortable: false, value: 'email' },
        { text: this.$t('companiesDetails.phone'), sortable: false, value: 'phone' },
        { text: this.$t('companiesDetails.role'), sortable: false, align: 'center', value: 'role' },
        {
          text: this.$t('users.state'),
          value: 'status',
          align: 'start',
          width: '120'
        },
        { text: '', align: 'end', width: '75', value: 'chat', sortable: false },
        {
          text: '',
          align: 'end',
          width: '75',
          value: 'actions',
          sortable: false
        }
      ],
      companyId: this.$route.params.id,
      employees: [],
      selectedUser: {},
      loadingDialog: false,
      addNewDialog: false,
      blockDialog: false,
      unblockDialog: false,
      setRoleDialog: false,
      setRoleEmployeeId: null,
      setRoleValue: null
    }
  },
  created () {
    this.getEmployees()
  },
  computed: {
    roles () {
      return this.$store.getters.enums.roles.filter((el) => el.id === roleTypes.employee || el.id === roleTypes.driver || el.id === roleTypes.manager)
    },
    customerServiceRoleTypes () {
      return this.$store.getters.enums.roles
    },
    userStatus () {
      return this.$store.getters.enums.userStatus
    }
  },
  methods: {
    getUserRole (role) {
      return getEnumDescription(this.customerServiceRoleTypes, role)
    },
    async setRoleEmployee () {
      this.loading = true
      try {
        await Service.post('CompanyUserManagement/SetRole', {
          userId: this.setRoleEmployeeId,
          role: this.setRoleValue
        })
        await this.getEmployees()
      } catch (e) {
      } finally {
        this.loading = false
        this.closeSetRoleDialog()
      }
    },
    refresh () {
      this.$refs.Employees.getData()
    },
    getUserStatus (status) {
      return getEnumDescription(this.userStatus, status)
    },
    openSetRoleEmployeeDialog (item) {
      this.setRoleDialog = true
      this.setRoleEmployeeId = item.id
      this.setRoleValue = item.role
    },
    closeSetRoleDialog () {
      this.setRoleDialog = false
    },
    block (user) {
      this.selectedUser = user
      this.blockDialog = true
    },
    blockDialogClose () {
      this.blockDialog = false
    },
    unblock (user) {
      this.selectedUser = user
      this.unblockDialog = true
    },
    unblockDialogClose () {
      this.unblockDialog = false
    },
    async getEmployees () {
      this.loading = true

      try {
        const { data } = await Service.get('/CustomerService/GetCompanyUsers', { companyId: this.$route.params.id })
        this.employees = data
      } catch (e) {}

      this.loading = false
    },
    async unblockDialogSubmit () {
      this.loadingDialog = true
      try {
        await Service.post('/CompanyUserManagement/Unblock', {
          userId: this.selectedUser.id
        })
        this.unblockDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.unblockSubmit')
        )
        this.getEmployees()
      } catch (e) {}
      this.loadingDialog = false
    },
    async blockDialogSubmit (reason) {
      this.loadingDialog = true
      try {
        await Service.post('/CompanyUserManagement/Block', {
          userId: this.selectedUser.id,
          blockReason: reason
        })
        this.blockDialog = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('users.blockSubmit')
        )
        this.getEmployees()
      } catch (e) {}
      this.loadingDialog = false
    },
    startConversationWithUser (userId) {
      startConversation(userId)
    }
    // goToProfile (item) {
    //   this.$router.push({ name: 'UserProfile', params: { userId: item.id } })
    // }
  }
}
</script>

<style>
</style>
