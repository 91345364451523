<template>
  <v-card flat>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="issues"
        :server-items-length="total"
        :loading="loading"
        hide-default-footer
        show-expand
        single-expand
      >
        <template v-slot:[`item.orderingCompanyUserId`]="{ item }">
          <div v-if="item.orderingCompanyUser">
            {{ item.orderingCompanyUser.name }}
            {{ item.orderingCompanyUser.surname }}
          </div>
        </template>
        <template v-slot:[`item.linkedCompanyUserId`]="{ item }">
          <div v-if="item.linkedCompanyUser">
            {{ item.linkedCompanyUser.name }}
            {{ item.linkedCompanyUser.surname }}
          </div>
          <div v-else>
            {{ $t("issues.notApplicable") }}
          </div>
        </template>
        <template v-slot:[`item.linkedCompanyDisplayId`]="{ item }">
          <div v-if="item.linkedCompanyDisplayId">
            {{ item.linkedCompanyDisplayId }}
          </div>
          <div v-else>
            {{ $t("issues.notApplicable") }}
          </div>
        </template>
        <template v-slot:[`item.dateOfRegistration`]="{ item }">
          {{ item.dateOfRegistration | dateFormat }}
        </template>
        <template v-slot:[`item.issueState`]="{ item }">
          <div v-if="item.depreciated">
            {{ $t("issues.closed") }}
          </div>
          <div v-else>
          {{ getState(item.issueState) }}
          </div>
        </template>
        <template v-slot:[`item.currentlyHandledBy`]="{ item }">
          <div v-if="item.user">
            {{ item.user.name }} {{ item.user.surname }}
          </div>
        </template>
        <template v-slot:[`item.actions`]>
          <v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="pa-6" :colspan="headers.length">
            <h4>{{ $t("issues.issueState") }}</h4>
            <v-data-table
              hide-default-footer
              :headers="detailsHeaders"
              :items="item.assignmentHistory"
            >
              <template v-slot:[`item.department`]="{ item }">
                {{ getDepartment(item.department) }}
              </template>
              <template v-slot:[`item.dateOfAssignment`]="{ item }">
                {{ item.dateOfAssignment | dateFormat }}
              </template>
              <template v-slot:[`item.assignedTo`]="{ item }">
                <div v-if="item.assignedTo">
                  {{ item.assignedTo.name }} {{ item.assignedTo.surname }}
                </div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <v-pagination
        v-if="pageCount > 1"
        :value="options.page"
        :length="pageCount"
        @input="options.page = $event"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import Service from '@/services'
import { mapGetters } from 'vuex'
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'Issues',
  data () {
    return {
      loading: true,
      total: 0,
      options: {},
      issues: [],
      users: [],
      headers: [
        {
          text: this.$t('issues.orderingUserFullName'),
          value: 'orderingCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'orderingCompanyDisplayId'
        },
        {
          text: this.$t('issues.linkedCompanyUserFullName'),
          value: 'linkedCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'linkedCompanyDisplayId'
        },
        {
          text: this.$t('issues.issueId'),
          value: 'issueDisplayId'
        },
        {
          text: this.$t('issues.category'),
          value: 'category'
        },
        {
          text: this.$t('issues.dateOfRegistration'),
          value: 'dateOfRegistration'
        },
        {
          text: this.$t('issues.issueState'),
          value: 'issueState'
        },
        {
          text: this.$t('issues.assignedTo'),
          value: 'currentlyHandledBy'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      detailsHeaders: [
        {
          text: this.$t('issues.department'),
          value: 'department',
          width: '15%'
        },
        {
          text: this.$t('issues.dateOfAssignment'),
          value: 'dateOfAssignment',
          width: '15%'
        },
        {
          text: this.$t('issues.assignedTo'),
          value: 'assignedTo',
          width: '20%'
        },
        {
          text: '',
          value: '',
          width: '55%',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getIssues()
    this.users = this.getUsers
  },
  computed: {
    ...mapGetters({
      enums: 'enums',
      getUsers: 'users/users'
    }),
    pageCount () {
      return Math.ceil(this.total / this.options.itemsPerPage)
    }
  },
  methods: {
    async getIssues () {
      this.loading = true
      const { page, itemsPerPage } = this.options

      try {
        const { data } = await Service.getList(
          `/CustomerService/GetCompanyIssues?companyId=${this.$route.params.id}`,
          page,
          itemsPerPage
        )
        this.issues = data.results
        this.total = data.count
      } catch (e) {}

      this.loading = false
    },
    getState (value) {
      return getEnumDescription(this.enums.issueState, value)
    },
    getUser (userId) {
      return this.users.find((user) => user.id === userId)
    },
    getDepartment (value) {
      return getEnumDescription(this.enums.departmentTypes, value)
    }
  }
}
</script>
<style>
</style>
