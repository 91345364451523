<template>
  <div>
    <v-card v-if="details" class="pa-0 pb-10" tile>
      <v-card-title>
        <h4 class="primary--text">{{ details.companyName }}</h4>
        <v-spacer />
        <v-btn color="primary" @click="$router.push({ name: 'AddIssueCustomerService' })" depressed>
          {{ $t('companiesDetails.createIssue')}}
        </v-btn>
      </v-card-title>
      <v-tabs v-model="tab" :height="60" background-color="grey lighten-5">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Information></Information>
        </v-tab-item>
        <v-tab-item>
          <Documents></Documents>
        </v-tab-item>
        <v-tab-item>
          <Ratings></Ratings>
        </v-tab-item>
        <v-tab-item>
          <Employees></Employees>
        </v-tab-item>
        <v-tab-item>
          <Issues></Issues>
        </v-tab-item>
        <v-tab-item>
          <CommentsTab />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Service from '@/services'
import Documents from './Tabs/Documents/Documents.vue'
import Employees from './Tabs/Employees.vue'
import Information from './Tabs/Information.vue'
import Ratings from './Tabs/Ratings/Ratings.vue'
import Issues from './Tabs/Issues.vue'
import CommentsTab from './Tabs/CommentsTab.vue'

export default {
  name: 'CompanyDetails',
  components: {
    Documents,
    Information,
    Employees,
    Ratings,
    Issues,
    CommentsTab
  },
  data () {
    return {
      tab: null,
      items: [
        this.$t('companiesDetails.info'),
        this.$t('companiesDetails.docs'),
        this.$t('companiesDetails.rating'),
        this.$t('companiesDetails.employees'),
        this.$t('companiesDetails.issues'),
        this.$t('companiesDetails.comments')
      ],
      details: null
    }
  },
  async created () {
    await this.getDetails()
  },
  methods: {
    async getDetails () {
      this.loading = true

      try {
        const { data } = await Service.get('/Company/GetCompanyDetails', { companyId: this.$route.params.id })
        this.details = data
      } catch (e) {}

      this.loading = false
    }
  }
}
</script>
<style scoped>
.v-tab {
  text-transform: none;
}
</style>
