<template>
  <div>
    <v-form ref="updateForm" v-model="valid" :disabled="submitLoading">
      <v-row>
        <v-col cols="4">
          <label>{{ $t('companiesDetails.name') }}</label>
          <v-text-field
            v-model="document.description"
            outlined
            dense
            :rules="required"
          ></v-text-field>
          <v-radio-group v-model="validOptions">
            <template v-slot:label>
              <span>{{ $t('companiesDetails.date') }}</span>
            </template>
            <v-radio :value="0">
              <template v-slot:label>
                <span>{{ $t('companiesDetails.indefinitely') }}</span>
              </template>
            </v-radio>
            <v-radio :value="1">
              <template v-slot:label>
                <v-menu
                  v-model="validToMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="validTo"
                      label="data"
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      single-line
                      hide-details
                      :disabled="
                        validIndefinitely() || validityNotApplicable()
                      "
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="validTo"
                    :first-day-of-week="1"
                    @input="validToMenu = false"
                  ></v-date-picker>
                </v-menu>
              </template>
            </v-radio>
            <v-radio
              v-if="document.type === 4"
              :value="2"
            >
              <template v-slot:label>
                <span>{{ $t('companiesDetails.notApplicable') }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6" offset="2">
          <label>{{ $t('companiesDetails.reminder') }}</label>
          <v-checkbox
            hide-details
            v-model="document.notificationMothBefore"
            :disabled="validIndefinitely() || validityNotApplicable()"
            :label="$t('companiesDetails.month')"
          ></v-checkbox>
          <v-checkbox
            hide-details
            v-model="document.notificationWeekBefore"
            :disabled="validIndefinitely() || validityNotApplicable()"
            :label="$t('companiesDetails.week')"
          ></v-checkbox>
          <v-checkbox
            hide-details
            v-model="document.notificationDayBefore"
            :disabled="validIndefinitely() || validityNotApplicable()"
            :label="$t('companiesDetails.day')"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div class="d-flex flex-column ml-8">
            <div
              v-for="file in document.files"
              :key="file.name"
              class="d-flex align-center mb-8"
            >
              <h4>{{ fileName(file.name) }}</h4>
              <v-icon
                color="blue"
                class="ml-auto pointer"
                small
                @click="getFile(file.name)"
                >mdi-download</v-icon
              >
            </div>
            <div>
              <v-btn
                color="primary"
                :disabled="loading"
                @click="removeDocument"
              >
                {{ $t('companiesDetails.delete') }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="6" offset="2" class="align-self-end">
          <div class="d-flex align-center">
            <div>
              <v-checkbox
                v-model="document.verified"
                :label="$t('companiesDetails.verified')"
                @change="verifyDocument"
                class="mt-0"
                :class="document.verified ? '' : 'mb-6'"
                hide-details
              ></v-checkbox>
              <small v-if="document.verified" class="ml-8 mb-0"
                >{{
                  document.dateOfVerification | dateFormat("DD.MM.YYYY")
                }}
                {{ document.name }} {{ document.surname }}</small
              >
            </div>
            <v-btn
              color="primary"
              outlined
              class="ml-12"
              :disabled="loading || !document.verified"
              :loading="loading"
              @click="updateDocument(document)"
            >
              {{ $t('companiesDetails.updateDoc') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import Service from '@/services'
import { dateFormat2 } from '@/utils/helpers.js'
export default {
  name: 'DocumentItem',
  props: ['document', 'loading'],
  data () {
    return {
      validToMenu: false,
      valid: true,
      submitLoading: false,
      required: [
        (v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
      ],
      validOptions: 0
    }
  },
  computed: {
    validTo: {
      get () {
        return dateFormat2(this.document.validTo)
      },
      set (newValue) {
        this.document.validTo = newValue
      }
    }
  },
  watch: {
    validOptions: function (val) {
      if (val === 2) {
        this.document.notificationMothBefore = false
        this.document.notificationWeekBefore = false
        this.document.notificationDayBefore = false
      }
    }
  },
  methods: {
    fileName (name) {
      var length = name.length
      if (length > 40) {
        return name.slice(0, 20) + '...' + name.slice(length - 10, length)
      }
      return name
    },
    verifyDocument () {
      this.$emit('verify', {
        companyId: this.$route.params.id,
        documentId: this.document.id,
        verified: this.document.verified
      })
    },
    updateDocument (document) {
      this.$refs.updateForm.validate()
      if (!this.valid) {
        return
      }

      this.$emit('update', {
        companyId: this.$route.params.id,
        document: {
          id: document.id,
          description: document.description,
          validIndefinitely: this.validIndefinitely(),
          validityNotApplicable: this.validityNotApplicable(),
          validTo: document.validTo,
          notificationMothBefore: document.notificationMothBefore,
          notificationWeekBefore: document.notificationWeekBefore,
          notificationDayBefore: document.notificationDayBefore
        }
      })
    },
    removeDocument () {
      this.$emit('remove', {
        companyId: this.$route.params.id,
        documentId: this.document.id
      })
    },
    reject () {
      this.$emit('reject')
      this.$refs.updateCommentForm.resetValidation()
    },
    submit () {
      this.$refs.updateCommentForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit')
      this.$refs.updateCommentForm.resetValidation()
    },
    async getFile (file) {
      try {
        const { data } = await Service.getFile(
          '/CustomerService/GetCompanyDocumentFile',
          {
            companyId: this.$route.params.id,
            fileName: file
          }
        )

        const blob = new Blob([data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {}
    },
    validIndefinitely () {
      return this.validOptions === 0
    },
    validityNotApplicable () {
      return this.validOptions === 2
    }
  },
  mounted () {
    if (this.document.validIndefinitely) {
      this.validOptions = 0
    } else if (this.document.validityNotApplicable) {
      this.validOptions = 2
    } else {
      this.validOptions = 1
    }
  }
}
</script>
