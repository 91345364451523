<template>
  <v-dialog v-if="history" v-model="dialog" persistent width="500px">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-data-table
        :headers="headers"
        :items="history"
        show-expand
        :expanded.sync="expanded"
        single-expand
        item-key="date"
        expand-icon="$sort">
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-2">
              <p>{{ $t('reviews.comment') }}:</p>
                {{ item.commentBeforeAction }}
            </td>
          </template>
          <template v-slot:item.date="{ item }">
            {{ item.date | dateFormat('DD.MM.YYYY HH:mm') }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ item.type | getDescription($store.getters.enums.reviewActionTypes) }}
          </template>
          <template v-slot:no-data>
            {{ $t('reviews.history') }}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="primary" @click="close">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'History',
  data () {
    return {
      dialog: false,
      history: null,
      expanded: [],
      headers: [
        {
          text: this.$t('common.date'),
          value: 'date',
          sortable: false
        },
        {
          text: '',
          value: 'type',
          sortable: false
        },
        {
          text: this.$t('common.user'),
          value: 'performer.fullName',
          sortable: false
        }
      ]
    }
  },
  methods: {
    open (history) {
      this.dialog = true
      this.history = history
    },
    close () {
      this.dialog = false
      this.history = null
    }
  }
}
</script>
