<template>
  <div>
    <v-card-title></v-card-title>
    <v-card-text> RODO </v-card-text>
  </div>
</template>
<script>
// import Service from '@/services'

export default {
  name: 'Rodo',
  data () {
    return {}
  },
  computed: {},
  created () {},
  methods: {}
}
</script>
<style scoped>
</style>
