<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" persistent>
    <v-card outlined>
      <v-card-title class="mb-4">
        <h4 class="primary--text">{{ title }}</h4>
      </v-card-title>
      <v-card-text v-show="!!message">{{ message }}</v-card-text>
      <v-card-text>
        <v-card-actions class="pa-0">
          <v-btn color="primary" outlined min-width="120" @click.native="cancel" depressed>
            {{ $t('common.no') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="120" @click.native="agree" depressed>
            {{ $t('common.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 350,
      zIndex: 200
    }
  }),
  methods: {
    open (title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve(true)
      this.dialog = false
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
